<template>
  <div class="login full-layout">
    <div class="login__container">
      <div class="login__image-block">
        <img alt="" class="login__image" src="../../assets/logo.png" />
        Вход в систему
      </div>

      <div class="login__inputs-block">
        <zem-input
          v-model="user.email"
          :errors="errors"
          :max-length="255"
          error-title="email"
          placeholder="Введите email"
          type="email"
          @input="errors = []"
        />
        <zem-input
          v-model="user.password"
          :errors="errors"
          :max-length="255"
          error-title="password"
          placeholder="Введите пароль"
          type="password"
          @input="errors = []"
        />
        <div class="login__inputs-block__buttons">
          <zem-button
            :disabled="user.email.length === 0 || user.password.length === 0 || loadingLogin"
            :loading="loadingLogin"
            @click="handleLogin"
            >Войти
          </zem-button>
          <zem-link class="mt-3" @click="$router.replace('/password-reset')"> Забыли пароль?</zem-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ZemInput from '../../components/ui/ZemInput.vue'
import ZemButton from '../../components/ui/ZemButton.vue'
import ZemLink from '../../components/ui/ZemLink.vue'
import AuthService from '@/services/auth.service'
import toastMessage from '@/helpers/messages'
import {errorHandler} from '@/assets/scripts/scripts'
import TokenService from '@/services/token.service'

export default {
  name: 'Login',
  components: {
    ZemInput,
    ZemButton,
    ZemLink,
  },
  data() {
    return {
      user: {
        email: '',
        password: '',
      },
      loading: false,
      message: '',
      errors: [],
      loadingLogin: false,
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn
    },
  },
  watch: {
    'user.email'() {
      this.clearForm()
    },
    'user.password'() {
      this.clearForm()
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.replace('/products')
    }
  },

  methods: {
    handleLogin() {
      this.loadingLogin = true
      this.clearForm()
      this.loading = true
      let isValid = true
      if (!isValid) {
        this.loading = false
        return
      }
      if (this.user.email && this.user.password) {
        AuthService.login(this.user)
          .then(r => {
            if (r.data.access_token) {
              this.$toast.success(toastMessage.authSuccess)
              TokenService.updateLocalAccessToken(r.data['access_token'])
              TokenService.updateLocalRefreshToken(r.data['refresh_token'])
              this.$store.commit('auth/loginSuccess')
              this.$router.replace('/')
              this.loadingLogin = false
            }
            return r.data
          })
          .catch(e => {
            this.loadingLogin = false
            this.errors = errorHandler(e)
          })
      }
    },
    clearForm() {
      this.errors = []
    },
  },
}
</script>

<style lang="scss" scoped>
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  align-content: center;
  height: 70%;

  &__container {
    width: 240px;
    padding: 25px;
    background: #ffffff;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08),
      0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }

  &__image {
    margin-bottom: 26px;
    height: 30px;
  }

  &__image-block {
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    color: $color-mine-shaft;
    justify-content: center;
    align-items: center;
    margin-bottom: 22px;
  }

  &__inputs-block {
    width: 100%;

    &__buttons {
      margin-top: 12px;
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    .zem-input-container {
      margin-bottom: 10px;
      line-height: 1;
    }
  }
}
</style>
